import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useMedia, useIdle } from 'react-use';
import { BreakpointQueryLarge } from '@hungryroot/tokens';

export const COOKIE_STORAGE_NAME = 'exit-modal';
export const COOKIE_ALREADY_SEEN_VALUE = '1';

const scrollSpeedCalculator = () => {
  const delay = 50;
  let lastPosition: number | null,
    newPosition: number,
    timer: ReturnType<typeof setTimeout>,
    delta: number;
  function clear() {
    lastPosition = null;
    delta = 0;
  }

  clear();
  return function () {
    newPosition = window.scrollY;
    if (lastPosition != null && newPosition < lastPosition) {
      delta = lastPosition - newPosition;
    }
    lastPosition = newPosition;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
};

export type Props = {
  idleDelayMilliseconds: number;
  children: ReactNode;
  onShow: () => void;
};

export function VisibilityController({
  onShow,
  children,
  idleDelayMilliseconds,
}: Props) {
  const [{ previousVisibility, currentVisibility }, setVisibilityState] =
    useState<{
      previousVisibility: boolean | null;
      currentVisibility: boolean;
    }>({
      previousVisibility: null,
      currentVisibility: false,
    });
  const idle = useIdle(idleDelayMilliseconds);
  const isDesktop = useMedia(BreakpointQueryLarge);

  const setVisibility = useCallback(
    (value: boolean) => {
      if (currentVisibility === true) {
        return;
      }
      setVisibilityState({
        previousVisibility: currentVisibility,
        currentVisibility: value,
      });
    },
    [currentVisibility, setVisibilityState]
  );

  useEffect(() => {
    if (idle !== true) {
      return;
    }

    setVisibility(true);
  }, [setVisibility, idle]);

  useEffect(() => {
    if (isDesktop === true || idle === false) {
      return;
    }

    let onScroll: () => void;
    const mobileScrollEvent = function () {
      const scrollSpeed = scrollSpeedCalculator();
      onScroll = () => {
        if (scrollSpeed() > 50) {
          setVisibility(true);
        }
      };
      document.addEventListener('scroll', onScroll, { passive: true });
    };

    // to prevent some weird:
    const timeout = setTimeout(mobileScrollEvent, 4000);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('scroll', onScroll);
    };
  }, [idle, setVisibility, isDesktop]);

  useEffect(() => {
    function onBlur() {
      setVisibility(true);
    }

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [setVisibility]);

  useEffect(() => {
    function onBlur() {
      setVisibility(true);
    }

    document.addEventListener('mouseleave', onBlur);

    return () => {
      document.removeEventListener('mouseleave', onBlur);
    };
  }, [setVisibility]);

  useLayoutEffect(() => {
    if (currentVisibility === true && previousVisibility === false) {
      onShow();
    }
  }, [onShow, currentVisibility, previousVisibility]);

  if (currentVisibility === true) {
    return <>{children}</>;
  }

  return null;
}
